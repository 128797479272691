<template>
  <v-row no-gutters>
    <v-col cols="12" class="pl-3">
      <v-tabs class="simulacao-tabs" active-class="activeMyClass" background-color="transparent">
        <v-tab :to="{ name: 'acordoPdv' }">PDV</v-tab>
        <v-tab :to="{ name: 'acordoEcommerce' }">E-commerce</v-tab>
        <v-tab :to="{ name: 'acordoCampanha' }">Campanha</v-tab>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>

export default {
  computed: {
    usuarioTipo() {
      if (this.$store.getters.getUsuario.id_fornecedor) {
        return "fornecedor";
      }
      return "rd";
    },
  }
}

</script>

<style>
.simulacao-tabs .activeMyClass:hover:before {
  background: none;
}

.simulacao-tabs .v-tab:before {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.simulacao-tabs .v-tabs-slider {
  width: 70%;
  margin: 0 15%;
}

.simulacao-tabs .v-tab {
  width: 150px;
  font-size: 1.2rem;
  font-weight: normal !important;
  letter-spacing: 0px;
  color: #999 !important;
  text-transform: none !important;
}

.simulacao-tabs .activeMyClass {
  background: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #000 !important;
}
</style>
